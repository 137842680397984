import React from "react";

const Map = () => {
  return (
    <div className="w-full -mb-2">
      <img className="w-full" src="/map.png" />
    </div>
  );
};

export default Map;
