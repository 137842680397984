import { Button, CircularProgress, TextField } from '@mui/material';
import emailjs from '@emailjs/browser';
import React from 'react';
import { useToast } from '../hooks/useToast';
import TextTranslation from '../components/TextTranslation';
import { t } from 'i18next';

const Contact = () => {
  const form = React.useRef<HTMLFormElement>(null);
  const [loading, setLoading] = React.useState(false);
  const { toast } = useToast();

  const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
    // .sendForm(
    //   "YOUR_SERVICE_ID",
    //   "YOUR_TEMPLATE_ID",
    //   form.current,
    //   "YOUR_PUBLIC_KEY"
    // )
    e.preventDefault();
    setLoading(true);

    if (form.current) {
      emailjs
        .sendForm(
          'service_tpeizy6',
          'template_fcw12he',
          form.current,
          '-ytTra3Oo2wfOwFNd'
        )
        .then(
          () => {
            setLoading(false);
            toast('Danke, dass sie uns kontaktiert haben!', 'success', 2000);
          },
          (error) => {
            setLoading(false);
            toast(
              `Kontaktaufnahme fehlgeschlagen! ${error.text}`,
              'error',
              2000
            );
          }
        );
    }
  };

  return (
    <div id="sec7" className="h-fit  relative pb-24">
      <div className="twoOverlay h-full w-full absolute z-10"></div>
      <div className="z-20 relative mx-auto pt-32">
        <h1 className="mt-0  text-center  block ">
          <TextTranslation>Kontakt</TextTranslation>
        </h1>
        <form
          ref={form}
          onSubmit={sendEmail}
          className="grid grid-cols-2 mx-auto w-auto lg:w-[70%] gap-4 px-10"
        >
          <div>
            <label className="block">
              <TextTranslation>Vorname</TextTranslation>
              <span className="text-secondary">*</span>
            </label>
            <TextField
              required
              name="firstName"
              placeholder={t('Vorname')}
              fullWidth
            />
          </div>
          <div>
            <label className="block">
              <TextTranslation>Nachname</TextTranslation>
              <span className="text-secondary">*</span>
            </label>
            <TextField
              required
              name="lastName"
              placeholder={t('Nachname')}
              fullWidth
            />
          </div>
          <div>
            <label className="block">
              <TextTranslation>E-Mail</TextTranslation>
              <span className="text-secondary">*</span>
            </label>
            <TextField
              required
              type="email"
              name="email"
              placeholder={t('E-Mail')}
              fullWidth
            />
          </div>
          <div>
            <label className="block">
              <TextTranslation>Telefon</TextTranslation>
              <span className="text-secondary">*</span>
            </label>
            <TextField
              type="number"
              required
              name="phone"
              placeholder={t('Telefon')}
              fullWidth
            />
          </div>
          <div className="col-span-2">
            <label className="block">
              <TextTranslation>Nachricht</TextTranslation>
              <span className="text-secondary">*</span>
            </label>
            <TextField
              required
              name="message"
              placeholder={t('Nachricht')}
              fullWidth
              multiline
              rows={5}
            />
          </div>
          <div className=" flex items-center gap-1">
            <input type="checkbox" className="w-fit" />
            <TextTranslation>
              Ich habe die Datenschutzrichtlinien gelesen und stimme ihnen zu.
            </TextTranslation>
          </div>
          <div className="col-span-2 mx-auto">
            <Button
              type="submit"
              variant="contained"
              sx={{
                width: '200px',
                backgroundColor: '#50B9C3',
                '&:hover': {
                  backgroundColor: '#409ca3',
                },
              }}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : t('Absenden')}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
