import React, { ReactNode, createContext, useEffect, useState } from 'react';
import { Gallary, Events } from './dto/dto';
import { useTranslation } from 'react-i18next';

interface WebsiteContextType {
  gallary: Gallary[];
  event: Events[];
  loading: boolean;
}

interface ApiResponse<T> {
  status: string;
  data: T[];
}

export const ProviderContext = createContext<WebsiteContextType>({
  gallary: [],
  event: [],
  loading: true,
});

export const ProductProvider = ({ children }: { children: ReactNode }) => {
  const { i18n } = useTranslation();
  const [gallary, setGallary] = useState<Gallary[]>([]);
  const [event, setEvent] = useState<Events[]>([]);
  const [loading, setLoading] = useState(true);
  const isFirstTime = React.useRef(true);

  React.useEffect(() => {
    if (!isFirstTime.current) {
      location.reload();
    }
    isFirstTime.current = false;
  }, [i18n.language]);

  useEffect(() => {
    const fetchGallaryData = fetch(
      'https://german.it-trendco.de/api/gallary/get',
      { headers: { ['Accept-Language']: i18n.language } }
    )
      .then((response) => response.json())
      .then((data: ApiResponse<Gallary>) => {
        if (data.status === 'OK') {
          setGallary(data.data);
        }
      });

    const fetchEventsData = fetch(
      'https://german.it-trendco.de/api/event/get',
      { headers: { ['Accept-Language']: i18n.language } }
    )
      .then((response) => response.json())
      .then((data: ApiResponse<Events>) => {
        if (data.status === 'OK') {
          setEvent(data.data);
        }
      });

    Promise.all([fetchGallaryData, fetchEventsData]).finally(() => {
      setLoading(false);
    });
  }, []);

  return (
    <ProviderContext.Provider value={{ gallary, event, loading }}>
      {children}
    </ProviderContext.Provider>
  );
};
