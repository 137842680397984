import { IconButton } from '@mui/material';
import './style.css';
import TextTranslation from '../components/TextTranslation';
import { useTranslation } from 'react-i18next';

const Home = () => {
  const { i18n } = useTranslation();
  return (
    <>
      <div
        id="home"
        className="h-[100vh] flex justify-center flex-col gap-24 container mx-auto px-4 items-center"
      >
        <div className="overlay" />
        <div className="bgHero bg " />
        <div className="flex flex-col z-20  justify-center items-center text-center mx-3">
          {i18n.language == 'ar' ? (
            <>
              <span className="text-primary text-[30px] sm:text-[50px] font-bold tracking-[13px] sm:tracking-[21px] ml-[17px]">
                <TextTranslation>Gemeinschaft</TextTranslation>
              </span>
              <span className="text-primary text-[30px] sm:text-[50px] font-bold">
                <TextTranslation>Die Deutsch-Arabische</TextTranslation>
              </span>
            </>
          ) : (
            <>
              <span className="text-primary text-[30px] sm:text-[50px] font-bold">
                <TextTranslation>Die Deutsch-Arabische</TextTranslation>
              </span>
              <span className="text-primary text-[30px] sm:text-[50px] font-bold tracking-[13px] sm:tracking-[21px] ml-[17px]">
                <TextTranslation>Gemeinschaft</TextTranslation>
              </span>
            </>
          )}

          <p className="text-primary text-lg text-pretty sm:text-xl">
            <TextTranslation>
              Hier finden Sie Informationen über uns und unsere Aktivitäten
            </TextTranslation>
          </p>
        </div>
        <div className="flex justify-center items-center z-20 relative bottom-0 gap-3">
          <IconButton className="bg-secondary w-10 h-10 hover:bg-[#2e92ab]">
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_137_1132)">
                <path
                  d="M23.3333 2.33333H4.66667C3.42899 2.33333 2.242 2.825 1.36683 3.70017C0.491665 4.57534 0 5.76232 0 7L0 21C0 22.2377 0.491665 23.4247 1.36683 24.2998C2.242 25.175 3.42899 25.6667 4.66667 25.6667H23.3333C24.571 25.6667 25.758 25.175 26.6332 24.2998C27.5083 23.4247 28 22.2377 28 21V7C28 5.76232 27.5083 4.57534 26.6332 3.70017C25.758 2.825 24.571 2.33333 23.3333 2.33333ZM25.6667 21C25.6667 21.6188 25.4208 22.2123 24.9832 22.6499C24.5457 23.0875 23.9522 23.3333 23.3333 23.3333H4.66667C4.04783 23.3333 3.45434 23.0875 3.01675 22.6499C2.57917 22.2123 2.33333 21.6188 2.33333 21V7C2.33333 6.38116 2.57917 5.78767 3.01675 5.35008C3.45434 4.9125 4.04783 4.66667 4.66667 4.66667H23.3333C23.9522 4.66667 24.5457 4.9125 24.9832 5.35008C25.4208 5.78767 25.6667 6.38116 25.6667 7V21Z"
                  fill="white"
                />
                <path
                  d="M23.0301 8.1865C22.927 8.07309 22.8026 7.9811 22.6639 7.91579C22.5253 7.85049 22.3751 7.81313 22.222 7.80587C22.069 7.79862 21.9159 7.82159 21.7717 7.87349C21.6275 7.92539 21.4949 8.00519 21.3816 8.10833L15.5751 13.3922C15.1443 13.7844 14.5827 14.0019 14.0001 14.0019C13.4175 14.0019 12.8558 13.7844 12.4251 13.3922L6.61859 8.10833C6.38962 7.9001 6.08731 7.79134 5.77816 7.806C5.469 7.82066 5.17833 7.95753 4.9701 8.1865C4.76186 8.41547 4.6531 8.71779 4.66776 9.02694C4.67502 9.18002 4.71236 9.33017 4.77765 9.46881C4.84293 9.60746 4.93489 9.73189 5.04826 9.835L9.66476 14.035L5.04943 18.2233C4.93098 18.3249 4.8341 18.4491 4.76453 18.5888C4.69495 18.7284 4.65409 18.8806 4.64436 19.0363C4.63463 19.192 4.65623 19.3481 4.70788 19.4953C4.75953 19.6425 4.84019 19.7779 4.94507 19.8934C5.04995 20.0089 5.17694 20.1022 5.31851 20.1677C5.46008 20.2333 5.61336 20.2698 5.76929 20.2751C5.92521 20.2803 6.08061 20.2543 6.22629 20.1985C6.37197 20.1426 6.50497 20.0581 6.61743 19.95L11.4509 15.5633C12.2057 16.0669 13.0927 16.3356 14.0001 16.3356C14.9074 16.3356 15.7945 16.0669 16.5493 15.5633L21.3828 19.95C21.4952 20.0581 21.6282 20.1426 21.7739 20.1985C21.9196 20.2543 22.075 20.2803 22.2309 20.2751C22.3868 20.2698 22.5401 20.2333 22.6817 20.1677C22.8233 20.1022 22.9502 20.0089 23.0551 19.8934C23.16 19.7779 23.2407 19.6425 23.2923 19.4953C23.344 19.3481 23.3656 19.192 23.3558 19.0363C23.3461 18.8806 23.3052 18.7284 23.2357 18.5888C23.1661 18.4491 23.0692 18.3249 22.9508 18.2233L18.3366 14.0338L22.9519 9.83383C23.1806 9.62568 23.3173 9.33524 23.332 9.02635C23.3466 8.71745 23.2381 8.41538 23.0301 8.1865Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_137_1132">
                  <rect width="28" height="28" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </IconButton>
          <IconButton className="bg-[#219EBC] w-10 h-10 hover:bg-[#2e92ab]">
            <svg
              width="31"
              height="31"
              viewBox="0 0 31 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M22.1072 11.887H17.9269V9.14531C17.9269 8.11567 18.6093 7.87563 19.0899 7.87563C19.5695 7.87563 22.0399 7.87563 22.0399 7.87563V3.34918L17.9772 3.33332C13.4671 3.33332 12.4408 6.70929 12.4408 8.86972V11.887H9.83252V16.5512H12.4408C12.4408 22.5371 12.4408 29.7495 12.4408 29.7495H17.9269C17.9269 29.7495 17.9269 22.466 17.9269 16.5512H21.6287L22.1072 11.887Z"
                fill="white"
              />
            </svg>
          </IconButton>
          <IconButton className="bg-[#219EBC] w-10 h-10 hover:bg-[#2e92ab]">
            <svg
              width="22"
              height="23"
              viewBox="0 0 22 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_137_1120)">
                <mask
                  id="mask0_137_1120"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="22"
                  height="23"
                >
                  <path d="M22 0H0V23H22V0Z" fill="white" />
                </mask>
                <g mask="url(#mask0_137_1120)">
                  <path
                    d="M13.093 9.73393L21.283 0H19.3422L12.2308 8.45183L6.55101 0H0L8.58902 12.7807L0 22.9882H1.94088L9.45067 14.0628L15.449 22.9882H22L13.0925 9.73393H13.093ZM10.4347 12.8933L9.56444 11.6206L2.6402 1.49386H5.62127L11.2092 9.66647L12.0795 10.9391L19.3431 21.5623H16.3621L10.4347 12.8938V12.8933Z"
                    fill="white"
                  />
                </g>
              </g>
              <defs>
                <clipPath id="clip0_137_1120">
                  <rect width="22" height="23" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </IconButton>
        </div>
      </div>
    </>
  );
};

export default Home;
