import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tab,
  Tabs,
  TextField,
} from '@mui/material';
import { Box, X } from 'lucide-react';
import React, { useContext } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Autoplay, Navigation, Pagination, Scrollbar } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ProviderContext } from '../WebsiteContext';
import { Events } from '../dto/dto';
import { useFile } from '../hooks/useFile';
import TextTranslation from '../components/TextTranslation';
import { t } from 'i18next';

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

const { getImageUrl } = useFile();
const About = () => {
  const [value, setValue] = React.useState(0);
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const { loading, event } = useContext(ProviderContext);

  const [oldEvents, setOldEvents] = React.useState<Events[]>([]);
  const [newEvents, setNewEvents] = React.useState<Events[]>([]);

  React.useEffect(() => {
    const today = new Date().toISOString().split('T')[0];
    const old: Events[] = [];
    const newEventsList: Events[] = [];

    event.forEach((item) => {
      if (item.date < today) old.push(item);
      else newEventsList.push(item);
    });

    setOldEvents(old);
    setNewEvents(newEventsList);
  }, [event]);

  const contentArray = [
    {
      id: 0,
      title: 'International Book Club Meeting',
      description:
        'Die Deutsch-Arabische Gemeinschaft e.V. in Würzburg lädt Sie herzlich zu einem Kennenlernen und zur Vorstellung unseres Vereins ein. Das Treffen findet am 2. Juni 2024 um 14 Uhr statt und zwar in den Räumlichkeiten des Adresse:',
      venue: {
        name: 'DITIB Türkisch-Islamischen Kulturvereins',
        street: 'Äußere Aumühlstraße 11',
        city: '97076 Würzburg',
      },
      report:
        'Diese Einladung richtet sich an Frauen und Männer sowie an alle, die sich für unseren Verein interessieren. Wir freuen uns auf Ihr Erscheinen! Für Ihr leibliches Wohl ist gesorgt, es erwarten Sie heiße und kalte Getränke sowie eine Auswahl an Kuchen und Gebäck.',
    },
    {
      id: 1,
      title: 'Community Art Exhibition',
      description:
        'invites you to explore the creativity of local artists at our annual art exhibition. The event will be held on August 12, 2024, at 3 PM at the following address:',
      venue: {
        name: 'Downtown Art Gallery',
        street: '456 Art Street',
        city: '67890 Metropolis',
      },
      report:
        'This report is open to art enthusiasts and the general public. We are excited to see you there! Enjoy a diverse range of artworks and meet the artists.',
    },
    {
      id: 2,
      title: 'Charity Fundraising Gala',
      description:
        'invites you to our charity fundraising gala. The event will take place on September 25, 2024, at 7 PM at the following address:',
      venue: {
        name: 'Grand Hotel Ballroom',
        street: '789 Charity Lane',
        city: '54321 Capital City',
      },
      report:
        'This report is open to all who wish to support our cause. We are looking forward to your presence! The evening will feature a gourmet dinner, live music, and a silent auction.',
    },
  ];
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const [bro, setBro] = React.useState<Events>();

  const openy = (item: Events) => {
    setBro(item);

    setOpen(true);
  };

  if (!loading) {
    return (
      <div id="sec2" className="h-fit relative">
        <div className="bg-primary w-full h-full absolute -z-10"></div>

        <Dialog
          open={open}
          onClose={handleClose}
          PaperProps={{
            component: 'div',
            style: { borderRadius: '20px', background: '#FDFCDC' },
          }}
        >
          <DialogTitle>{bro?.title}</DialogTitle>

          <DialogContent className="flex flex-col gap-7">
            <div>
              <img
                className="w-full max-h-[300px] rounded-xl object-contain"
                src={getImageUrl(bro?.image as string)}
              />
            </div>
            <span className="leading-6">{bro?.report}</span>
            {/* <DialogContentText>{bro?.description}</DialogContentText> */}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>
              <span className="text-secondary">Close</span>
            </Button>
          </DialogActions>
        </Dialog>

        <div className="py-20">
          <div className="flex justify-center">
            <h2 className="inline text-center my-10 lg:max-w-1/4 w-full">
              <TextTranslation>
                Hier finden Sie Informationen über uns und unsere Aktivitäten
              </TextTranslation>
            </h2>
          </div>
          <div className="grid grid-cols-12 px-10">
            <div className="col-span-12 lg:col-span-4 lg:col-start-2 flex flex-col mx-auto w-[300px] sm:w-[400px] lg:w-auto ">
              <div className="flex justify-between gap-6">
                <div>
                  <img src="/about3.png" className="w-full" />
                </div>
                <div>
                  <img src="/about2.png" className="w-full" />
                </div>
              </div>
              <div>
                <img src="/about.png" className="w-full" />
              </div>
            </div>

            <div className="col-span-12 lg:col-span-6  lg:col-start-7 relative text-center lg:text-left">
              <Tabs
                value={value}
                onChange={handleChange}
                textColor="secondary"
                indicatorColor="secondary"
                centered
                data-aos="fade-right"
                data-aos-once
                data-aos-delay="350"
                data-aos-duration="1000"
              >
                <Tab className="text-[12px] sm:text-sm" label={t('Alt')} />
                <Tab className="text-[12px] sm:text-sm" label={t('Neu')} />
              </Tabs>
              <TabPanel value={value} index={0} className="mt-1">
                <div className="text-balance mb-1 block">
                  <Swiper
                    style={{ cursor: 'pointer' }}
                    autoplay={{ delay: 1500 }}
                    loop={true}
                    spaceBetween={10}
                    slidesPerView={1}
                    centeredSlides
                    draggable
                    scrollbar={{ draggable: false }}
                    modules={[Pagination, Navigation, Scrollbar, Autoplay]}
                  >
                    {oldEvents.map((item) => (
                      <SwiperSlide
                        onClick={() => openy(item)}
                        key={item.id}
                        className="mb-10 h-full min-h-[250px]"
                      >
                        <div className="h-full min-h-[250px] flex text-center bg-[#50b9c31f] transition-all p-2 hover:bg-[#50b9c370] border-solid border-[#50B9C3] rounded-xl max-sm:text-sm mx-10 flex-col justify-center items-center">
                          <p>
                            {item.title}
                            {item.description}
                          </p>
                          <div className="text-left">
                            <span className="font-bold block">
                              {item?.name}
                            </span>
                            <span className="font-bold block">
                              {item?.city}
                            </span>
                            <span className="font-bold block">
                              {item?.street}
                            </span>
                          </div>
                          <p className="text-balance">{item?.report}</p>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </TabPanel>
              <TabPanel value={value} index={1} className="mt-1">
                <div className="text-balance mb-1 block">
                  {/* autoplay={{ delay: 1500 }} */}
                  <Swiper
                    style={{ cursor: 'pointer' }}
                    loop={true}
                    spaceBetween={10}
                    slidesPerView={1}
                    centeredSlides
                    draggable
                    scrollbar={{ draggable: false }}
                    modules={[Pagination, Navigation, Scrollbar, Autoplay]}
                  >
                    {newEvents.length > 0 ? (
                      newEvents.map((item) => (
                        <SwiperSlide
                          onClick={() => openy(item)}
                          key={item.id}
                          className="mb-10"
                        >
                          <div className="flex text-center bg-[#50b9c31f] transition-all p-2 hover:bg-[#50b9c370] border-solid border-[#50B9C3] rounded-xl max-sm:text-sm mx-10 flex-col justify-center items-center">
                            <p>
                              {item.title}
                              {item.description}
                            </p>
                            <div className="text-left">
                              <span className="font-bold block">
                                {item?.name}
                              </span>
                              <span className="font-bold block">
                                {item?.city}
                              </span>
                              <span className="font-bold block">
                                {item?.street}
                              </span>
                            </div>
                            <p className="text-balance">{item?.report}</p>
                          </div>
                        </SwiperSlide>
                      ))
                    ) : (
                      <SwiperSlide className="mb-10">
                        <div className="flex text-center h-[200px] bg-[#50b9c31f] transition-all p-2 hover:bg-[#50b9c370] border-solid border-[#50B9C3] rounded-xl max-sm:text-sm mx-10 flex-col justify-center items-center">
                          <TextTranslation>
                            Keine neue Veranstaltungen
                          </TextTranslation>
                        </div>
                      </SwiperSlide>
                    )}
                  </Swiper>
                </div>
              </TabPanel>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default About;
