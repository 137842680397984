import { Dialog, IconButton } from '@mui/material';
import { X } from 'lucide-react';
import React, { useContext } from 'react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

import './GallaryFull.css';

import {
  Autoplay,
  Navigation,
  Thumbs,
  FreeMode,
  Pagination,
} from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useFile } from '../hooks/useFile';
import { ProviderContext } from '../WebsiteContext';
import { Gallary } from '../dto/dto';
import TextTranslation from '../components/TextTranslation';
const { getImageUrl } = useFile();

const Card = ({
  openDialog,
  data,
}: {
  openDialog: () => void;
  data: Gallary;
}) => {
  return (
    <div
      onClick={openDialog}
      className="relative group overflow-hidden w-56 h-56 cursor-pointer hover:scale-110 transition-all duration-500 mx-auto"
    >
      <img
        className="w-full h-full object-cover rounded-xl"
        src={getImageUrl(data.image)}
        alt=""
      />
      <div className="absolute top-0 transition-all w-full text-clip text-center text-lg p-2 bg-gradient-to-b from-black/70 to-transparent rounded-t-xl">
        <span className="text-white font-bold text-center">{data.title}</span>
      </div>
      <div className="absolute bottom-0 w-full p-2 bg-gradient-to-t from-black/70 to-transparent rounded-b-xl text-center">
        <span className="text-white text-center">{data.description}</span>
      </div>
    </div>
  );
};

const GallaryFull = () => {
  const [open, setOpen] = React.useState(false);
  const [thumbsSwiper, setThumbsSwiper] = React.useState(null);
  const { loading, gallary } = useContext(ProviderContext);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleClose = () => {
    setOpen(false);
    setThumbsSwiper(null);
  };

  const openy = () => {
    setOpen(true);
  };

  if (!loading) {
    return (
      <>
        <div id="GallaryFull" className="h-fit  relative flex items-center">
          <div className="twoOverlay h-full w-full absolute z-10"></div>
          <Dialog fullScreen open={open} onClose={handleClose}>
            <div className="bg-primary w-full h-full">
              <div className="absolute z-50">
                <IconButton onClick={handleClose}>
                  <X />
                </IconButton>
              </div>

              <Swiper
                style={
                  {
                    cursor: 'grab',
                    '--swiper-navigation-color': '#219dbc',
                    '--swiper-pagination-color': '#219dbc',
                  } as any
                }
                navigation={true}
                pagination={{
                  type: 'fraction',
                }}
                spaceBetween={10}
                slidesPerView={1}
                centeredSlides
                draggable
                thumbs={{ swiper: thumbsSwiper }}
                modules={[Navigation, Autoplay, Navigation, Thumbs, Pagination]}
                className="mySwiper2"
              >
                {gallary.map((item, i) => {
                  return (
                    <SwiperSlide key={i}>
                      <div className="w-full sm:w-[80%] mx-auto h-full">
                        <img
                          className="w-full h-full object-contain rounded-xl"
                          src={getImageUrl(item.image)}
                        />
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
              {/* navigation swiper */}
              <Swiper
                onSwiper={setThumbsSwiper as any}
                spaceBetween={10}
                slidesPerView={4}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiper"
              >
                {gallary.map((item, i) => {
                  return (
                    <SwiperSlide key={i}>
                      <div className="w-full mx-auto h-[100px]">
                        <img
                          className="w-full h-full object-cover rounded-xl"
                          src={getImageUrl(item.image)}
                        />
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </Dialog>
          <div className=" mx-auto px-4 z-20 py-24">
            <div className="text-center mb-10 mt-24">
              <h1 className="inline">
                <TextTranslation>Vollständige Galerie</TextTranslation>
              </h1>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 min-h-[40dvh]">
              {gallary.map((item, i) => {
                return <Card key={i} openDialog={openy} data={item} />;
              })}
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return <div></div>;
  }
};

export default GallaryFull;
