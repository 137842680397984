import { Button } from '@mui/material';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Autoplay, Navigation, Pagination, Scrollbar } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ProviderContext } from '../WebsiteContext';
import { Gallary as GallaryDto } from '../dto/dto';
import { useFile } from '../hooks/useFile';
import TextTranslation from '../components/TextTranslation';

const Card = ({ data }: { data: GallaryDto }) => {
  const { getImageUrl } = useFile();

  return (
    <div id="sec8" className="max-w-sm mx-auto z-20">
      <div className="bg-primary shadow-md rounded-lg overflow-hidden">
        <img
          className="w-full h-56 object-cover"
          src={getImageUrl(data.image)}
          alt="Gallery Image"
        />
        <div className="px-4 mt-1">
          <p className="text-xl font-semibold my-0">{data.title}</p>
          <p className="text-gray-700 text-sm mt-0">{data.description}</p>
        </div>
      </div>
    </div>
  );
};

const Gallary = () => {
  const { loading, gallary } = useContext(ProviderContext);

  if (!loading) {
    return (
      <>
        <div className="h-fit max-lg:py-10 lg:h-[100vh] relative flex items-center">
          <div className="twoOverlay h-full w-full absolute z-10"></div>
          <div className="container mx-auto px-4 z-20">
            <div className="text-center mb-10 mt-24">
              <h1 className="inline">
                <TextTranslation>Galerie</TextTranslation>
              </h1>
            </div>
            <div>
              <Swiper
                style={{ cursor: 'grab' }}
                loop={true}
                spaceBetween={10}
                slidesPerView={2}
                autoplay={{ delay: 1500 }}
                centeredSlides
                draggable
                modules={[Pagination, Navigation, Scrollbar, Autoplay]}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 2,
                  },
                  1024: {
                    slidesPerView: 3,
                  },
                }}
              >
                {gallary.map((item) => {
                  return (
                    <SwiperSlide className="mb-10" key={item.id}>
                      <Card data={item} />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
              <Link to={'/Galerie'}>
                <Button
                  size="small"
                  className=" mx-auto w-[20%] flex justify-center mb-2 bg-secondary hover:bg-[#3f8da0] text-white font-bold py-2 px-4 rounded mt-2"
                >
                  <TextTranslation>Mehr</TextTranslation>
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return <div></div>;
  }
};

export default Gallary;
