import React from 'react';
import './style.css';
import TextTranslation from '../components/TextTranslation';

const Section3 = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div
      id="sec3"
      className="h-fit max-lg:py-10 lg:h-[100vh] relative flex items-center"
    >
      <div className="twoOverlay h-full w-full absolute z-10"></div>
      <div className="bgSection bg" />

      <div className="container mx-auto px-4 z-20">
        <div className="text-center mb-10 mt-20">
          <h1 className="inline">
            <TextTranslation>Unsere Ziele</TextTranslation>
          </h1>
          <p>
            <TextTranslation>
              ein gemeinnütziger Verein, eingetragen am 3. April 2024 in
              Würzburg und hat folgende Ziele:
            </TextTranslation>
          </p>
        </div>
        <div className="flex flex-col gap-4 mx-10 text-center lg:flex-row lg:justify-between pb-24">
          <p className="transition-all bg-[#fdfcdc70] hover:bg-primary my-1 font-bold  lg:text-xl p-8 w-full lg:w-1/3 flex items-center rounded-xl">
            <TextTranslation>
              Förderung der Integration in die deutsche Gesellschaft unter
              Bewahrung der arabisch-islamischen Kultur unter Berücksichtigung
              der multikulturellen deutschen Gesellschaft.
            </TextTranslation>
          </p>
          <p className="transition-all bg-[#fdfcdc70] hover:bg-primary  my-1 font-bold  lg:text-xl p-8 w-full lg:w-1/3 flex items-center rounded-xl">
            <TextTranslation>
              Förderung von Kennenlernen und Zusammenarbeit zwischen Mitgliedern
              der Gemeinschaft.
            </TextTranslation>
          </p>
          <p className="transition-all bg-[#fdfcdc70] hover:bg-primary  my-1 font-bold  lg:text-xl p-8 w-full lg:w-1/3 flex items-center rounded-xl">
            <TextTranslation>
              Sensibilisierung für die Gefahren von Rassismus und Extremismus in
              all seinen Formen.
            </TextTranslation>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Section3;
